<template>
  <div>
    <Modal v-model="setModal" width="800">
      <p slot="header" class="text-center">批量设置开放市场</p>
      <div class="m-b-10">请选择需要批量设置的条件</div>
      <Form ref="query" :model="queryModel" :rules="queryRules">
        <FormItem label="选择资产（可多选）" prop="assetIds">
          <i-select size="small" v-model="queryModel.assetIds" multiple @on-change="handleChangeAsset">
            <i-option :value="item.id" v-for="item in assetArray" :key="item.id">{{ item.name }}</i-option>
          </i-select>
        </FormItem>
        <FormItem label="选择站点（可多选）">
          <i-select size="small" placeholder="选择站点（可通过关键字搜索）" multiple clearable filterable
            v-model="queryModel.stationIds">
            <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ `${item.name} -
              ${item.assetName}` }}</i-option>
          </i-select>
        </FormItem>
        <FormItem label="选择资源类型（可多选）">
          <i-select size="small" placeholder="资源类型（可通过关键字搜索）" multiple clearable filterable
            v-model="queryModel.resourcetypeIds">
            <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
              <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
              </Option>
            </OptionGroup>
          </i-select>
        </FormItem>
        <FormItem label="选择等级">
          <i-select v-model="queryModel.level" clearable size="small" placeholder="请选择等级">
            <Option v-for="item in levelArray" :value="item.id" :key="'resLevel_' + item.id">{{ item.name }}</Option>
          </i-select>
        </FormItem>
        <FormItem label="开放市场" prop="open">
          <Select v-model="queryModel.open" size="small" placeholder="请选择开放市场">
            <Option v-for="item in openArray" :value="item.value" :key="'resOpen_' + item.value">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="setModal = false">取消</Button>
        <Button type="primary" @click="onConfirmSet">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { deviceResMixins } from '../mixins/index'
import { getResourceTypeList } from '@/api/product/asset'
import { setOpen } from '@/api/product/resource'
export default {
  props: {
    assetArray: {
      type: Array,
      required: true
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  mixins: [deviceResMixins],
  data () {
    return {
      setModal: false,
      resourceTypeArray: [],
      queryModel: {
        assetIds: [],
        stationIds: [],
        resourcetypeIds: [],
        level: null,
        open: 1
      },
      queryRules: {
        assetIds: [
          { required: true, type: 'array', message: ' ', trigger: 'change' }
        ],
        // stationIds: [
        //   { required: true, type: 'array', message: ' ', trigger: 'change' }
        // ],
        // resourcetypeIds: [
        //   { required: true, type: 'array', message: ' ', trigger: 'change' }
        // ],
        // level: [
        //   { required: true, type: 'number', message: ' ', trigger: 'change' }
        // ],
        open: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    levelArray () {
      return this.$store.state.deviceManage.levelArray
    }
  },
  methods: {
    showModal () {
      Object.assign(this.$data.queryModel, this.$options.data().queryModel)
      this.setModal = true
    },
    handleChangeAsset (val) {
      if (this.queryModel.assetIds.length) {
        const rimAssetIds = []
        const rimAssets = this.assetArray.filter(x => this.queryModel.assetIds.includes(x.id))
        rimAssets.forEach(x => {
          if (x.rimAssetIds.includes(',')) {
            x.rimAssetIds.split(',').forEach(r => {
              rimAssetIds.push(Number(r))
            })
          } else {
            rimAssetIds.push(Number(x.rimAssetIds))
          }
        })
        // 去重
        const assetIds = Array.from(new Set(rimAssetIds))
        // 调用mixins中的方法
        this.getStationData(assetIds)

        this.getResourceTypeData()
      } else {
        this.stationArray = []
      }
    },
    /**
    * 获取资源类型数据
    */
    getResourceTypeData () {
      getResourceTypeList({ assetIds: JSON.stringify(this.queryModel.assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    onConfirmSet () {
      this.$refs.query.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误，并提交？',
            onOk: () => {
              const postData = {
                assetIdList: this.queryModel.assetIds,
                level: this.queryModel.level,
                resourcetypeIdList: this.queryModel.resourcetypeIds,
                stationIdList: this.queryModel.stationIds,
                open: this.queryModel.open
              }
              setOpen(postData).then(res => {
                if (res && res.errcode === 0) {
                  this.onSuccess()
                  this.setModal = false
                  this.$Notice.success({ desc: '操作成功' })
                }
              })
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确填写信息' })
        }
      })
    }
  },
  watch: {
    stationArray: {
      deep: true,
      immediate: true,
      handler (val) {
        // 获取选择资产包含的站点id集合
        const stations = this.stationArray.filter(s => this.queryModel.assetIds.includes(s.assetId))
        const stationIds = (stations && stations.length) ? stations.map(x => x.id) : []
        // 资产删除后，移除对应的站点id集合
        this.queryModel.stationIds = stationIds.length ? this.queryModel.stationIds.filter(x => stationIds.includes(x)) : []
      }
    }
  }
}
</script>
